export const clickBannerEv = (mobileHyperlink, router) => {
  const httpsStr = 'https'

  if (!mobileHyperlink) return false // 如果連結不存在

  // 網站 「連結」 轉向
  if (mobileHyperlink.includes(httpsStr)) {
    location.href = mobileHyperlink
    return false
  }

  // 如果要導向 slot 頁的項目
  const goSlotPapeItem = ['slot', 'fish']
  const hyperLinkSplit = mobileHyperlink.split('/')
  const isGotoSlotPage = goSlotPapeItem.includes(hyperLinkSplit[0])

  // 如果符合導向 slot 的項目
  if (isGotoSlotPage) {
    router.push({
      name: 'slot',
      params: {
        slotName: hyperLinkSplit[1],
      },
    })
  } else console.log(`mobileHyperlink => ${mobileHyperlink}`)
}
